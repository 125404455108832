import styled from "styled-components"
import { Link } from "gatsby"

export interface StyledProps {
  buttonFullWidth?: boolean
}

export const StyledLink = styled(Link)<StyledProps>`
  ${({ buttonFullWidth }) => buttonFullWidth && "width: 100%;"};
`

export const TextWrapper = styled.span`
  display: block;
`
