import React from "react"

import * as Styled from "./styles"

const TextContent: React.FC<Styled.StyledProps> = ({
  children,
  center,
  color,
  weight,
  fontSize,
}) => (
  <Styled.TextContent
    fontSize={fontSize}
    center={center}
    color={color}
    weight={weight}
  >
    {children}
  </Styled.TextContent>
)

export default TextContent
