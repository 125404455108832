import styled from "styled-components"
import { StyledSection } from "components/ui/Section/styles"

export const Wrapper = styled(StyledSection)`
  margin: 1rem 0 13rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    margin: 1rem 0;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    margin: 1rem 0 9rem;
  }
`

export const Box = styled.div`
  ${({ theme }) => theme.breakpoints.smallDesktop} {
    &:first-child {
      margin-right: 20%;
      margin-top: 5rem;
    }
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    &:first-child {
      flex-basis: 50%;
    }

    &:second-child {
      flex-basis: 43%;
      margin-left: 7%;
    }
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    &:first-child {
      flex-basis: 50%;
      min-width: 698px;
      margin-right: initial;

      p {
        max-width: 550px;
      }
    }

    &:second-child {
      flex-basis: 43%;
      margin-left: 7%;
    }
  }
`
