import styled from "styled-components"

export interface StyledProps {
  fullWidth?: boolean
}

export const StyledSection = styled.section<StyledProps>`
  padding: 0 1.5rem;
  ${({ fullWidth }) => fullWidth && "width: 100%;"}
  max-width: 100vw;

  ${({ theme }) => theme.breakpoints.tablet} {
    max-width: 100%;
  }
`
