import styled from "styled-components"

export interface StyledProps {
  lighter?: boolean
}

export const SubTitle = styled.h2<StyledProps>`
  color: ${({ lighter, theme }) =>
    lighter ? theme.colors.text2 : theme.colors.text1};
  font-family: ${({ theme }) => theme.fonts.main2};
  font-size: ${({ theme }) => theme.mobile.subTitle.size};
  font-weight: ${({ theme }) => theme.mobile.subTitle.weight};
  line-height: ${({ theme }) => theme.mobile.subTitle.lineHeight};

  &:first-letter {
    text-transform: uppercase;
  }
  
  .lighter {
    color: ${({ theme }) => theme.colors.text2};
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: ${({ theme }) => theme.desktop.subTitle.size};
    line-height: ${({ theme }) => theme.desktop.subTitle.lineHeight};
  }
`
