import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Banner from "components/ui/Banner"
import HeroImage from "assets/svgs/hero-1.svg"
import ImageWrapper from "components/ui/ImageWrapper"

import * as Styled from "./styles"

import { LandingPagesProps, SectionBanner } from "types/interfaces"
import { LandingPageType } from "types/enums"

const HeroBanner: React.FC<LandingPagesProps> = ({ landingType }) => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "hero section" } }) {
        frontmatter {
          content
          linkTo
          linkText
        }
      }
    }
  `)

  const heroBanner: SectionBanner =
    landingType === LandingPageType.HOME
      ? {
          content:
            "Processing COIs over email is time consuming, unorganized and costly. Movezee simplifies the COI process between tenants, vendors and management companies to save time and money. Reduce the time spent on COIs by up to 90%!",
        }
      : markdownRemark.frontmatter

  const title =
    landingType === LandingPageType.HOME ? (
      <p>
        COI's made <span className="lighter">easy</span>
      </p>
    ) : (
      <p>
        Automated COI process that <span className="lighter">saves time</span>
      </p>
    )

  return (
    <Styled.Wrapper>
      <Styled.Box>
        <Banner
          title={{ title, hero: true }}
          textContent={{ textContent: heroBanner.content }}
          subtitle={{ subtitle: "" }}
        />
      </Styled.Box>
      <Styled.Box>
        <ImageWrapper>
          <HeroImage />
        </ImageWrapper>
      </Styled.Box>
    </Styled.Wrapper>
  )
}

export default HeroBanner
