import styled from "styled-components"

export interface StyledProps {
  white?: boolean
  lighter?: boolean
  main?: boolean
  largeScreenFontSize?: string
}

export const Title = styled.h1<StyledProps>`
  color: ${({ lighter, white, theme }) =>
    lighter
      ? theme.colors.text2
      : white
      ? theme.colors.text3
      : theme.colors.text1};
  font-family: ${({ theme }) => theme.fonts.main2};
  font-size: ${({ theme }) => theme.mobile.title.size};
  font-weight: ${({ theme }) => theme.mobile.title.weight};
  line-height: ${({ theme }) => theme.mobile.title.lineHeight};
  padding-bottom: 1.5rem;

  &:first-letter {
    text-transform: uppercase;
  }

  .lighter {
    color: ${({ theme }) => theme.colors.text2};
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    ${({ main, theme }) =>
      main && `font-size: ${theme.smallDesktop.mainTitle.size}`};
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: ${({ theme, main, largeScreenFontSize }) =>
      largeScreenFontSize
        ? largeScreenFontSize
        : main
        ? theme.desktop.mainTitle.size
        : theme.desktop.title.size};
    line-height: ${({ theme, main }) =>
      main
        ? theme.desktop.mainTitle.lineHeight
        : theme.desktop.title.lineHeight};
    padding-bottom: 2.5rem;
  }
`
