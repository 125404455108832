import styled from "styled-components"
import { FlexWrapper } from "components/ui/FlexWrapper/styles"

export const Wrapper = styled.div`
  background: linear-gradient(0deg, #005778 -27.36%, #00aeef 128.68%);
  width: 100%;
  max-width: 100vw;

  ${({ theme }) => theme.breakpoints.tablet} {
    border-radius: 24px;
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    max-width: 1440px;
  }
`
export const ContentWrapper = styled(FlexWrapper)`
  ${({ theme }) => theme.breakpoints.smallDesktop} {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
`

export const Box = styled.div`
  &:first-child {
    margin: 4rem 2rem 2rem;

    ${({ theme }) => theme.breakpoints.smallDesktop} {
      margin: 5rem 0 5rem 5%;
      width: 70%;
    }

    ${({ theme }) => theme.breakpoints.largeDesktop} {
      margin: 8rem 0 8rem 5%;
      width: 60%;
    }
  }

  &:nth-child(2) {
    display: none;
    margin-bottom: 4rem;
    width: 100%;

    ${({ theme }) => theme.breakpoints.smallDesktop} {
      display: block;
      align-self: center;
      margin: 0 2% 0 3%;
      width: 30%;
    }

    ${({ theme }) => theme.breakpoints.desktop} {
      margin: 0 2% 0 3%;
      width: 40%;
    }
  }
`

export const FormWrapper = styled.form`
  margin-top: 2rem;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    align-content: space-between;
  }

  ${({ theme }) => theme.breakpoints.largeDesktop} {
    margin-top: 2.5rem;
  }
`
