import styled from "styled-components"
import { ImageWrapper } from "components/ui/ImageWrapper/styles"

export interface StyledProps {
  reverse?: boolean
  separateTitle?: boolean
}

export const Wrapper = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
    align-items: flex-start;
  }
`

export const IllustrationWrapper = styled(ImageWrapper)<StyledProps>`
  flex-basis: 45%;
  margin-right: 5%;

  ${({ reverse }) =>
    reverse &&
    `margin-right: 0;
  margin-left: 5%;`}
`

export const InfoContentWrapper = styled.div<StyledProps>`
  ${({ theme, separateTitle }) =>
    separateTitle &&
    `background: linear-gradient(0deg, ${theme.colors.background1} 0%, rgba(255, 255, 255, 0) 100%);
    padding: 0 2.5rem;
  border-radius: 24px;`};

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    ${({ separateTitle }) =>
      separateTitle && `flex-basis: 40%; margin-left: 3%; margin-right: 7%;`}
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    p {
      font-size: 18px;
    }
  }
`

export const BgWrapper = styled.div<StyledProps>`
  ${({ theme, separateTitle }) =>
    !separateTitle &&
    `background: linear-gradient(0deg, ${theme.colors.background1} 0%, rgba(255, 255, 255, 0) 100%);
    padding: 3rem;
  border-radius: 24px;`};

  ${({ theme }) => theme.breakpoints.desktop} {
    h1 p {
      line-height: 70px;
    }
  }

  ${({ separateTitle }) => separateTitle && `h1 {padding: 0 1.5rem 1.5rem; }`}

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    ${({ separateTitle }) =>
      separateTitle &&
      `display: flex; h1 {
        flex-basis: 44%;
        margin-right: 2%;
        margin-left: 4%;
      padding:0;
    }`}
  }
`

export const FlexWrapper = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    flex-basis: 40%;
    margin-left: 10%;

    ${({ reverse }) =>
      reverse &&
      `margin-left: 0;
  margin-right: 10%;`}

    ${({ separateTitle }) =>
      separateTitle &&
      `flex-basis: unset; margin-left: auto; margin-right: auto;`}
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    margin-left: 3%;
    margin-right: 7%;

    ${({ reverse }) =>
      reverse &&
      `margin-left: 7%;
  margin-right: 3%;`}

    ${({ separateTitle }) =>
      separateTitle && ` margin-left: auto; margin-right: auto;`}
  }
`
