import React, { useState } from "react"

import Banner from "components/ui/Banner"
import ImageWrapper from "components/ui/ImageWrapper"
import ContactIllustration from "assets/svgs/contact-image.svg"
import Button from "components/ui/Button"

import * as Styled from "./styles"

import { LandingPagesProps } from "types/interfaces"
import theme from "styles/theme"
import LabeledInput from "components/ui/LabeledInput"
import { client } from "lib/axios"

const contactBannerTexts = {
  title: "See what Movezee can do for you",
  textContent:
    "Do you want to test our demo or ask a few questions?\nFill out the form and let our team get back to you.",
}

interface ServerValidationError {
  [key: string]: string
}
interface FormErrors {
  [key: string]: string
}

const ContactBanner: React.FC<LandingPagesProps> = ({ landingType }) => {
  const [nameValue, setNameValue] = useState<string>("")
  const [phoneValue, setPhoneValue] = useState<string>("")
  const [emailValue, setEmailValue] = useState<string>("")
  const [validationErrors, setValidationErrors] = useState<FormErrors>({})

  const [isPhoneWrongFormat, setIsPhoneWrongFormat] = useState<boolean>(false)

  const handleFormSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    try {
      const res = await client.post("/contact", {
        name: nameValue,
        phone: phoneValue,
        email: emailValue,
      })

      if (res.status === 200) {
        alert("Thank you for contacting us. We will get back to you soon.")

        // reset form values and errors
        setNameValue("")
        setPhoneValue("")
        setEmailValue("")
        setValidationErrors({})
        setIsPhoneWrongFormat(false)
      }
    } catch (error) {
      const typedError = error as any
      const validationErrors: ServerValidationError[] =
        typedError.response?.data?.errorsValidation

      if (validationErrors) {
        const errorsObject = validationErrors.reduce(
          (acc: FormErrors, currentError) => {
            Object.entries(currentError).forEach(([key, value]) => {
              acc[key] = value
            })
            return acc
          },
          {}
        )
        setValidationErrors(errorsObject)
      }
    }
  }

  const getFormattedPhoneNumber = (phoneString: string): string => {
    const trimmedString = phoneString
      .trim()
      .replaceAll(" ", "")
      .replaceAll("-", "")
      .replaceAll("(", "")
      .replaceAll(")", "")

    if (trimmedString.length < 5) {
      return trimmedString.replace(/^(\d{3})(\d)/, "($1) $2")
    }

    if (trimmedString.length >= 5 && trimmedString.length < 7) {
      return trimmedString.replace(/^(\d{3})(\d{1,3})/, "($1) $2")
    }

    return trimmedString.replace(/^(\d{3})(\d{3})(\d{1,4})/, "($1) $2-$3")
  }

  const phoneNumberValidation = (phoneNumber: string) => {
    const phoneRegex = /\(\d{3}\) \d{3}[-\s]?\d{4}$/

    if (phoneNumber.match(phoneRegex) || phoneNumber === "") {
      setIsPhoneWrongFormat(false)
      setValidationErrors({ ...validationErrors, phone: "" })
    } else {
      setIsPhoneWrongFormat(true)
    }
  }

  return (
    <Styled.Wrapper id="contact-form">
      <Styled.ContentWrapper>
        <Styled.Box>
          <Banner
            title={{ title: contactBannerTexts.title, bottom: true }}
            subtitle={{ subtitle: "" }}
            textContent={{
              textContent: contactBannerTexts.textContent,
              color: theme.colors.text3,
              weight: "400",
            }}
          />
          <Styled.FormWrapper
            autoComplete="off"
            onSubmit={e => handleFormSubmit(e)}
          >
            <LabeledInput
              value={nameValue}
              onChangeHandler={setNameValue}
              placeholder="Your name"
              label="Name"
              isError={!!validationErrors?.name}
            />
            <LabeledInput
              value={phoneValue}
              onChangeHandler={(value: string) =>
                setPhoneValue(getFormattedPhoneNumber(value))
              }
              validationFunction={phoneNumberValidation}
              isError={isPhoneWrongFormat || !!validationErrors?.phone}
              placeholder="(555) 555-5555"
              inputType="text"
              label="Phone"
            />
            <LabeledInput
              value={emailValue}
              onChangeHandler={setEmailValue}
              placeholder="Your email"
              label="Email"
              inputType="email"
              isError={!!validationErrors?.email}
            />
            <Button
              activeShadow={true}
              landingType={landingType}
              mobileMargin="2.5rem 0"
              disableMargin={true}
              type="submit"
              primary
              shadow
            >
              {"Contact us"}
            </Button>
          </Styled.FormWrapper>
        </Styled.Box>
        <Styled.Box>
          <ImageWrapper>
            <ContactIllustration />
          </ImageWrapper>
        </Styled.Box>
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  )
}

export default ContactBanner
