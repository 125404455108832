import React, { useState } from "react"

import Button from "components/ui/Button"
import TextContent from "components/ui/TextContent"

import * as Styled from "./styles"

import { LandingPagesProps } from "types/interfaces"

interface Props extends LandingPagesProps {
  textParagraphs: string[]
  expandButtonText?: string
  isButtonHidden?: boolean
}

const ToggleText: React.FC<Props> = ({
  textParagraphs,
  expandButtonText,
  landingType,
  isButtonHidden,
}) => {
  const [isOpen, toggleText] = useState(isButtonHidden)
  const paragraphsToHide = textParagraphs.slice(0, textParagraphs.length - 1)

  return (
    <Styled.Wrapper>
      <TextContent>{textParagraphs[0]}</TextContent>
      {textParagraphs.length > 1 &&
        isOpen &&
        paragraphsToHide.map((paragraph, index) => (
          <TextContent key={index}>{paragraph}</TextContent>
        ))}
      {!isButtonHidden && (
        <Button
          landingType={landingType}
          secondary
          disableMargin
          onClick={() => toggleText(!isOpen)}
        >
          {isOpen ? "Hide" : expandButtonText ? expandButtonText : "Read more"}
        </Button>
      )}
    </Styled.Wrapper>
  )
}

export default ToggleText
