import styled from "styled-components"

interface StyledProps {
  isError?: boolean
  value?: string
}

export const LabelWrapper = styled.label<StyledProps>`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.text3};
  weight: 400;
  margin: 1rem 0 0.5rem;
`

export const InputWrapper = styled.input<StyledProps>`
  outline: none !important;
  font-size: 16px;
  weight: 400;
  border: ${({ isError }) =>
    isError ? "1px solid red" : "1px solid rgba(0, 87, 120, 0.2)"};
  border-radius: 8px;
  width: 100%;
  padding: 0.94rem 0.5rem;
  color: ${({ theme, value }) => value && theme.colors.text1};

  &::placeholder {
    color: rgba(0, 87, 120, 0.5);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  ${({ theme }) => theme.breakpoints.smallDesktop} {
    margin: 0 0.5rem 0 0;
    padding: 0.9rem 0.5rem;
    font-size: 14px;
    width: auto;
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 1rem 0 0;
    font-size: 16px;
  }
`
