import React from "react"

import TextContent from "./ToggleText"
import Title from "components/ui/Title"
import SubTitle from "components/ui/SubTitle"

import * as Styled from "./styles"

import { LandingPagesProps } from "types/interfaces"

interface Props extends Styled.StyledProps, LandingPagesProps {
  image?: React.ReactNode
  title: string | React.ReactFragment
  subtitle: {
    text: string
    lighter?: boolean
  }
  textParagraphs: string[]
  expandButtonText?: string
  isButtonHidden?: boolean
}

const MainInfoBlock: React.FC<Props> = ({
  reverse,
  image,
  title,
  subtitle,
  textParagraphs,
  separateTitle,
  landingType,
  expandButtonText,
  isButtonHidden,
}) => (
  <Styled.Wrapper reverse={reverse}>
    {image && (
      <Styled.IllustrationWrapper reverse={reverse}>
        {image}
      </Styled.IllustrationWrapper>
    )}
    <Styled.FlexWrapper reverse={reverse} separateTitle={separateTitle}>
      <Styled.BgWrapper separateTitle={separateTitle}>
        <Title>{title}</Title>
        <Styled.InfoContentWrapper separateTitle={separateTitle}>
          <SubTitle lighter={subtitle.lighter}>{subtitle.text}</SubTitle>
          <TextContent
            textParagraphs={textParagraphs}
            landingType={landingType}
            expandButtonText={expandButtonText}
            isButtonHidden={isButtonHidden}
          />
        </Styled.InfoContentWrapper>
      </Styled.BgWrapper>
    </Styled.FlexWrapper>
  </Styled.Wrapper>
)

export default MainInfoBlock
