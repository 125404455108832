import React, { Dispatch, SetStateAction } from "react"
import FlexWrapper from "../FlexWrapper"

import * as Styled from "./styles"

interface Props {
  onChangeHandler: Dispatch<SetStateAction<string>> | ((value: string) => void)
  validationFunction?: (phoneNumber: string) => void
  isError?: boolean
  label: string
  placeholder: string
  value: string
  inputType?: "text" | "email" | "number"
}

const LabeledInput: React.FC<Props> = ({
  onChangeHandler,
  validationFunction,
  isError,
  label,
  placeholder,
  value,
  inputType = "text",
}) => {
  return (
    <FlexWrapper>
      <Styled.LabelWrapper isError={isError}>
        <label htmlFor={`input-${label}`}>{label}</label>
      </Styled.LabelWrapper>
      <Styled.InputWrapper
        isError={isError}
        onChange={e => {
          onChangeHandler(e.target.value)
          if (validationFunction) validationFunction(e.target.value)
        }}
        value={value}
        placeholder={placeholder}
        type={inputType}
        id={`input-${label}`}
      />
    </FlexWrapper>
  )
}

export default LabeledInput
