import React from "react"

import Title from "components/ui/Title"
import SubTitle from "components/ui/SubTitle"
import TextContent from "components/ui/TextContent"
import FlexWrapper from "components/ui/FlexWrapper"

import * as Styled from "./styles"

interface Props {
  title: {
    title: string | React.ReactFragment
    hero?: boolean
    bottom?: boolean
  }
  subtitle: {
    subtitle: string
    ligther?: boolean
  }
  textContent: {
    textContent?: string
    color?: string
    weight?: string
  }
}

const Banner: React.FC<Props> = ({
  title: { title, hero, bottom },
  subtitle: { subtitle, ligther },
  textContent: { textContent, color, weight },
}) => {
  return (
    <FlexWrapper>
      <Title main={hero} white={bottom}>
        {title}
      </Title>
      {subtitle && <SubTitle lighter={ligther}>{subtitle}</SubTitle>}
      {textContent && (
        <TextContent color={color} weight={weight}>
          {textContent.split("\n").map((text, index) => (
            <Styled.TextWrapper key={index}>{text}</Styled.TextWrapper>
          ))}
        </TextContent>
      )}
    </FlexWrapper>
  )
}

export default Banner
