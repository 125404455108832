import React from "react"

import * as Styled from "./styles"

const Title: React.FC<Styled.StyledProps> = ({
  lighter,
  main,
  white,
  children,
  largeScreenFontSize,
}) => (
  <Styled.Title
    lighter={lighter}
    main={main}
    white={white}
    largeScreenFontSize={largeScreenFontSize}
  >
    {children}
  </Styled.Title>
)

export default Title
