import styled from "styled-components"

export interface StyledProps {
  center?: boolean
  color?: string
  weight?: string
  fontSize?: {
    mobile?: string
    desktop?: string
  }
}

export const TextContent = styled.p<StyledProps>`
  color: ${({ theme, color }) => (color ? color : theme.colors.text1)};
  text-align: ${props => (props.center ? "center" : "left")};
  font-size: ${({ theme, fontSize }) =>
    fontSize?.mobile ? fontSize.mobile : theme.mobile.text.size};
  font-weight: ${({ theme, weight }) =>
    weight ? weight : theme.mobile.text.weight};
  line-height: ${({ theme }) => theme.mobile.text.lineHeight};

  ${({ theme }) => theme.breakpoints.desktop} {
    font-size: ${({ theme, fontSize }) =>
      fontSize?.desktop ? fontSize.desktop : theme.desktop.text.size};
    line-height: ${({ theme }) => theme.desktop.text.lineHeight};
  }
`
